var exports = {};
exports = is;
is.fn = isFunction;
is.str = isString;
is.num = isNumber;
is.obj = isObject;
is.lit = isLiteral;
is.bol = isBoolean;
is.truthy = isTruthy;
is.falsy = isFalsy;
is.arr = isArray;
is.null = isNull;
is.def = isDef;
is.in = isIn;
is.promise = isPromise;
is.stream = isStream;

function is(v) {
  return function (d) {
    return d == v;
  };
}

function isFunction(d) {
  return typeof d == "function";
}

function isBoolean(d) {
  return typeof d == "boolean";
}

function isString(d) {
  return typeof d == "string";
}

function isNumber(d) {
  return typeof d == "number";
}

function isObject(d) {
  return typeof d == "object";
}

function isLiteral(d) {
  return d.constructor == Object;
}

function isTruthy(d) {
  return !!d == true;
}

function isFalsy(d) {
  return !!d == false;
}

function isArray(d) {
  return d instanceof Array;
}

function isNull(d) {
  return d === null;
}

function isDef(d) {
  return typeof d !== "undefined";
}

function isPromise(d) {
  return d instanceof Promise;
}

function isStream(d) {
  return !!(d && d.next);
}

function isIn(set) {
  return function (d) {
    return !set ? false : set.indexOf ? ~set.indexOf(d) : d in set;
  };
}

export default exports;